import translations from '../../../utils/translations'
import { ROLE_FORM } from '../../../constants/roles'
import { GFPP } from './manifest-commons'
import _ from 'lodash'

export const createLimitMessageManifest = () => {
  return {
    relatedToRole: ROLE_FORM,
    displayName: translations.t('manifest.limitMessage.displayName'),
    behavior: {
      essential: {
        enabled: true,
        text: translations.t('manifest.limitMessage.restoreText'),
      },
      duplicatable: false,
    },
    gfpp: {
      desktop: {
        iconButtons: {
          connect: GFPP.REMOVE,
        },
        helpId: undefined, // TODO
      },
    },
  }
}
