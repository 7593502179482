import { STEP_POSITION } from './constants'
import _ from 'lodash'
import {
  ROLE_NEXT_BUTTON,
  ROLE_PREVIOUS_BUTTON,
  ROLE_SUBMIT_BUTTON,
  STEP_ROLE,
  MESSAGES_STEPS_ROLES,
} from '../../../constants/roles'

export const isSortableStep = (stepData: StepData) => {
  return _.eq(stepData.role, STEP_ROLE)
}

export const getStepPosition = (stepsData: StepData[], stepIndex: number): string => {
  const lastStepPosition = getNonMessagesStepCount(stepsData) - 1
  if (stepIndex === 0 && stepIndex === lastStepPosition) {
    return STEP_POSITION.FIRST_AND_LAST_STEP
  }

  if (stepIndex === 0) {
    return STEP_POSITION.FIRST_STEP
  }

  if (stepIndex === lastStepPosition) {
    return STEP_POSITION.LAST_STEP
  }

  return STEP_POSITION.OTHER
}

export const isFirstStep = (stepsData: StepData[], stepIndex: number): boolean => {
  const stepPosition = getStepPosition(stepsData, stepIndex)
  return (
    stepPosition === STEP_POSITION.FIRST_STEP || stepPosition === STEP_POSITION.FIRST_AND_LAST_STEP
  )
}

export const isLastStep = (stepsData: StepData[], stepIndex: number): boolean => {
  const stepPosition = getStepPosition(stepsData, stepIndex)
  return (
    stepPosition === STEP_POSITION.LAST_STEP || stepPosition === STEP_POSITION.FIRST_AND_LAST_STEP
  )
}

export const getReorderedSteps = (
  steps: StepData[],
  srcIndex: number,
  destIndex: number,
  currentStepRef: ComponentRef,
): { currentStep: number; stepsData: StepData[] } => {
  const stepsData = _.cloneDeep(steps)
  stepsData.splice(destIndex, 0, stepsData.splice(srcIndex, 1)[0])
  return { currentStep: _.findIndex(stepsData, { componentRef: currentStepRef }), stepsData }
}

export const findPrimaryConnection = (componentDefinition: ComponentStructure) =>
  _.find(componentDefinition.connections.items, {
    isPrimary: true,
  })

export const isNavigationButton = (role: string): boolean => {
  return role === ROLE_PREVIOUS_BUTTON || role === ROLE_NEXT_BUTTON || role === ROLE_SUBMIT_BUTTON
}

export const getStepTitle = (stepDefinition: ComponentStructure): string => {
  const stepConnection = findPrimaryConnection(stepDefinition)
  return JSON.parse(stepConnection.config).title
}

export const getConfigFromDefinition = (
  componentDefinition: ComponentStructure,
): ComponentConfig => {
  const componentConnection = findPrimaryConnection(componentDefinition)
  return JSON.parse(_.get(componentConnection, 'config'))
}

export const getFieldsLabelsForDuplication = (fieldsList: FormField[]): Partial<FormField>[] => {
  return fieldsList.map((field: FormField) => {
    return {
      crmLabel: _.get(field, 'crmLabel, '),
      collectionFieldKey: _.get(field, 'collectionFieldKey'),
    }
  })
}

export const getFieldLabelForDuplication = (
  fieldStructure: ComponentStructure,
): Partial<FormField> => {
  const fieldConfig = getConfigFromDefinition(fieldStructure)
  return {
    crmLabel: _.get(fieldConfig, 'crmLabel, '),
    collectionFieldKey: _.get(fieldConfig, 'collectionFieldKey'),
  }
}

export const isNonMessageStep = (role) => !isMessageStep(role)

export const isMessageStep = (role) => _.includes(MESSAGES_STEPS_ROLES, role)

export const getMessagesStep = (steps: StepData[]) =>
  _.filter(steps, (step) => isMessageStep(step.role))

export const getNonMessagesStep = (steps: StepData[]) =>
  _.filter(steps, (step) => isNonMessageStep(step.role))

export const getMessagesStepCount = (steps: StepData[]) => _.size(getMessagesStep(steps))

export const getNonMessagesStepCount = (steps: StepData[]) => _.size(getNonMessagesStep(steps))

export const getMesssageStepIndexOf = (steps: StepData[], role) => _.findIndex(steps, { role })
