import _ from 'lodash'
import { CRM_TYPES } from '../../../../../constants/crm-types-tags'

export const getFieldById = (fields: FormField[], fieldCompId: string): FormField =>
  _.find(fields, (field: FormField) => field.componentRef.id === fieldCompId)

export const getFieldWithNonPrimaryRole = (fields: FormField[], role: string): FormField =>
  _.find(fields, (field: FormField) => {
    const connection = _.find(
      field.nonPrimaryConnections,
      (connection: ComponentConnection) => connection.role === role,
    )

    return !!connection
  })

export const getFieldsWithNonPrimaryRole = (fields: FormField[], role: string): FormField[] =>
  _.filter(
    fields,
    (field: FormField) =>
      !!_.find(
        field.nonPrimaryConnections,
        (connection: ComponentConnection) => connection.role === role,
      ),
  )

export const getEmailFields = (fields: FormField[]): FormField[] =>
  _.filter(fields, (field: FormField) => field.crmType === CRM_TYPES.EMAIL)
