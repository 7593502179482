import { FormPlugin, FormsFieldPreset } from '@wix/forms-common'
import {
  GeneralDropdown,
  makeGeneralDropdownOption,
} from '../../../general-fields/definitions/general-dropdown'
import _ from 'lodash'
import { COUNTRIES_CODES, DEFAULT_COUNTRY_CODE } from '../constants'
import { COMPLEX_PHONE_ROLES } from '../../../../../../../constants/roles'
import { countryCodesToOptions } from '../../../../../utils'

export class ComplexPhoneDropdown extends GeneralDropdown {
  public get fieldType(): FieldPreset {
    return FormsFieldPreset.COMPLEX_PHONE_DROPDOWN
  }

  public get label() {
    return this.translate('complexPhone.dropdown.defaultTitle')
  }

  public get role() {
    return COMPLEX_PHONE_ROLES.DROPDOWN
  }

  public get disableCollectionSync() {
    return true
  }

  protected get layout() {
    return {
      x: 0,
      y: 0,
      width: 120,
      height: 42,
    }
  }
  protected get data(): any {
    return {
      type: 'SelectableList',
      options: this._options,
      value: this._defaultOption,
      placeholder: {
        value: '',
        text: '',
      },
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        layout: {
          row: 0,
          col: 0,
          width: 36,
        },
        addFieldPanelData: null,
      },
    }
  }

  protected get mobileHints() {
    return {
      recommendedWidth: 87,
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
    }
  }

  private get _options() {
    return countryCodesToOptions(COUNTRIES_CODES)
  }

  private get _defaultOption() {
    return DEFAULT_COUNTRY_CODE
  }

  public get overrideCommonLayoutTransformation(): {
    [key: string]: {
      fromCommonLayoutToValue?: (propValue: PROPERTIES) => PROPERTIES
      fromValueToCommonLayout?: (propValue: PROPERTIES) => PROPERTIES
    }
  } {
    return {
      textAlignment: {
        fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_TEXT_ALIGNMENT =>
          value === 'right' || value === 'left' ? 'left' : value,
      },
    }
  }
}
