export const FORMS_SERVER = {
  BASE_URL: '/_api/wix-form-builder-web',
  BASE_URL_V2: '/_api/wix-forms',
}

export const FORMS_SERVERLESS = {
  URL: '/_serverless/forms-editor-service',
  ENDPOINT: {
    USERS: 'users',
    RESTRICTIONS: 'restrictions',
  },
}

export const FEATURES_MANAGER_URL = '/_api/premium-features-manager/v1'
export const PLATFORMIZED_PAYMENTS_URL = '/_api/payment-services-web/payments/v2'
export const PREMIUM_STORE_URL = '/_api/store/v1'
