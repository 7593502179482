import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import { ContactSyncProps } from '../../../panels/contact-sync-panel/components/contact-sync-panel'
import { SyncField, SyncData } from '../../../panels/contact-sync-panel/constants/types'
import { getFormPlugins } from '../plugins/utils'
import { isNewCustomFieldId, areSyncDataEqual } from '../../../panels/contact-sync-panel/utils'
import {
  getSyncedFieldsCount,
  createSyncFieldsFromFormFields,
  mapComplexInnerFieldTypeToServerCrmType,
} from './utils'
import RemoteApi from '../../../panels/commons/remote-api'
import _ from 'lodash'
import { CustomFieldResponse } from '../../../constants/field-types'
import { CUSTOM_FIELD, CRM_TAGS, CRM_TYPES, crmTypesTags } from '../../../constants/crm-types-tags'
import { CustomTypes, FormsFieldPreset } from '@wix/forms-common'
import { getCountableFields } from '../fields/utils'
import { Classification, ContactField, DataType } from '@wix/ambassador-wix-contacts-webapp/http'
import { FIELDS } from '../../../constants/roles'

const migrateCRMTypes = [CRM_TYPES.DATE, CRM_TYPES.WEBSITE]

const crmClassifier = {
  [CRM_TYPES.DATE]: {
    [CRM_TAGS.BIRTHDAY]: Classification.BIRTHDAY,
    [CRM_TAGS.ANNIVERSARY]: Classification.ANNIVERSARY,
    [CRM_TAGS.OTHER]: Classification.DATE,
  },
  [CRM_TYPES.WEBSITE]: {
    [CRM_TAGS.WORK]: Classification.WORK_WEBSITE,
    [CRM_TAGS.PERSONAL]: Classification.PERSONAL_WEBSITE,
    [CRM_TAGS.OTHER]: Classification.WEBSITE,
  },
}

export default class ContactSyncApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private experiments: Experiments

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  public async loadInitialPanelData({
    formComponentRef,
    componentConnection,
  }: {
    formComponentRef: ComponentRef
    componentConnection: ComponentConnection
  }): Promise<Partial<ContactSyncProps>> {
    const plugins = getFormPlugins(componentConnection)

    return Promise.all([
      this.coreApi.fields.getFieldsSortByXY(formComponentRef),
      this.remoteApi.getCustomFields(),
    ]).then(([fieldsOnStage, customFields]) => ({
      loading: false,
      fields: createSyncFieldsFromFormFields(
        getCountableFields(fieldsOnStage),
        plugins,
        customFields,
      ),
      customFields,
      preset: _.get(componentConnection, 'config.preset'),
    }))
  }

  private async _createCustomField(
    customFieldName: string,
    customFieldType: CustomTypes,
  ): Promise<string> {
    const { id } = await this.remoteApi.createCustomField({
      name: customFieldName,
      fieldType: customFieldType,
    })

    return id
  }

  private _saveCustomField(customField: CustomFieldResponse): Promise<void> {
    return this.remoteApi.updateCustomFieldName({ id: customField.id, newName: customField.name })
  }

  public async createCustomField(
    fieldComponentRef: ComponentRef,
    customFieldName: string,
    customFieldType: CustomTypes,
  ): Promise<SyncData> {
    const id = await this._createCustomField(customFieldName, customFieldType)
    const updatedSync = {
      crmType: CUSTOM_FIELD,
      crmTag: undefined,
      customFieldId: id,
      customFieldName,
    }
    await this.saveCurrentSyncData(fieldComponentRef, updatedSync)
    return updatedSync
  }

  public async saveCustomField(
    fieldComponentRef: ComponentRef,
    customFieldToUpdate: CustomFieldResponse,
  ): Promise<SyncData> {
    await this._saveCustomField(customFieldToUpdate)
    const updatedSync = {
      crmType: CUSTOM_FIELD,
      crmTag: undefined,
      customFieldId: customFieldToUpdate.id,
      customFieldName: customFieldToUpdate.name,
    }
    await this.saveCurrentSyncData(fieldComponentRef, updatedSync)
    return updatedSync
  }

  private _shouldSaveInnerFieldsSyncData(field: FormField) {
    return field.role === FIELDS.ROLE_FIELD_COMPLEX_ADDRESS_WIDGET
  }

  private async _saveComplexInnerFieldsSyncData(field: FormField, data: SyncData) {
    const { childFields } = field

    return Promise.all(
      childFields.map((childField) => {
        const syncData = {
          crmTag: data.crmTag,
          customFieldId: childField.customFieldId,
          customFieldName: childField.customFieldName,
          crmType:
            data.crmType === 'customField'
              ? 'customField'
              : mapComplexInnerFieldTypeToServerCrmType.complexAddress[childField.fieldType],
        }

        return this.coreApi.setComponentConnectionsByConnectionRole(
          childField.componentRef,
          syncData,
          childField.role,
        )
      }),
    )
  }

  public async saveCurrentSyncData(fieldRef: ComponentRef, data: SyncData) {
    const field: FormField = await this.coreApi.fields.getField(fieldRef)
    const shouldSaveInnerFieldsSyncData = this._shouldSaveInnerFieldsSyncData(field)

    if (shouldSaveInnerFieldsSyncData) {
      await this._saveComplexInnerFieldsSyncData(field, data)
    }

    return this.coreApi.setComponentConnection(fieldRef, data, false)
  }

  public async saveContactSyncFieldsAndReturnSyncedFieldsCount(
    fields: SyncField[],
    initialFields: SyncField[],
    customFields: CustomFieldResponse[],
    initialCustomFields: CustomFieldResponse[],
  ): Promise<{
    syncedFieldsCount: number
  }> {
    const initialCustomFieldsById = _.keyBy(initialCustomFields, 'id')
    const initialFieldsById = _.keyBy(initialFields, 'componentRef.id')

    const changedFields = fields.filter(
      (field) =>
        !areSyncDataEqual(field.syncData, initialFieldsById[field.componentRef.id].syncData),
    )
    const fieldsWithNewCustomField = changedFields.filter((field) =>
      isNewCustomFieldId(field.syncData.customFieldId),
    )
    const fieldsWithChangedSync = changedFields.filter(
      (field) => !isNewCustomFieldId(field.syncData.customFieldId),
    )

    const customFieldsToUpdate = customFields
      .filter((customField) => !isNewCustomFieldId(customField.id))
      .filter(
        ({ name, id }) =>
          initialCustomFieldsById[id].name !== name &&
          _.some(fieldsWithChangedSync, (field) => field.syncData.customFieldId === id),
      )

    const fieldsWithCreatedNewCustomField = await Promise.all(
      fieldsWithNewCustomField.map<Promise<{ componentRef: ComponentRef; syncData: SyncData }>>(
        async (field) => {
          const customFieldId = await this._createCustomField(
            field.syncData.customFieldName,
            field.customFields[0],
          )
          return {
            componentRef: field.componentRef,
            syncData: { ...field.syncData, customFieldId },
          }
        },
      ),
    )

    await Promise.all(customFieldsToUpdate.map(this._saveCustomField.bind(this)))

    const fieldsToUpdateConfig = _.flatten([fieldsWithCreatedNewCustomField, fieldsWithChangedSync])

    await Promise.all(
      fieldsToUpdateConfig.map((field) =>
        this.saveCurrentSyncData(field.componentRef, field.syncData),
      ),
    )

    return {
      syncedFieldsCount: getSyncedFieldsCount(
        fields.map(({ syncData: { crmType, customFieldId } }) => ({
          crmType,
          customFieldId,
        })),
      ),
    }
  }

  private _migrateField(componentRef: ComponentRef, contactField: ContactField) {
    const config: Partial<FormField> = {
      crmType: CUSTOM_FIELD,
      crmTag: null,
      customFieldId: null,
    }

    if (contactField) {
      const {
        id,
        fieldType,
        details: { name, dataType },
      } = contactField
      if (fieldType === 'BUILT_IN' && name === 'birthday') {
        config.crmType = CRM_TYPES.BIRTHDATE
        config.fieldType = FormsFieldPreset.BIRTHDAY
      }
      if (fieldType === 'USER_DEFINED') {
        config.customFieldId = id
        config.crmType = CUSTOM_FIELD
        config.customFieldName = name
        switch (dataType) {
          case DataType.Date:
            config.fieldType = FormsFieldPreset.GENERAL_DATE_PICKER
            break
          case DataType.URL:
            config.fieldType = FormsFieldPreset.GENERAL_URL
            break
        }
      }
    }

    return this.coreApi.setComponentConnection(componentRef, config)
  }

  public async applyContactMigration() {
    const forms: ComponentRef[] = await this.coreApi.getAllFormsRefs()
    const fields = await Promise.all(
      _.map(forms, (formRef) => this.coreApi.fields.getFieldsSortByXY(formRef)),
    )
    const classifiedFields = _(fields)
      .flatten()
      .filter(({ crmType }) => _.includes(migrateCRMTypes, crmType))
      .map(({ componentRef, crmTag, crmType }) => ({
        classification: crmClassifier[crmType][crmTag],
        externalId: componentRef.id,
        componentRef,
      }))
      .value()

    if (_.isEmpty(classifiedFields)) {
      return
    }

    const classifiedById = _.keyBy(classifiedFields, 'externalId')

    const contactsMigrationData = await this.remoteApi.migrateFields({
      externalFields: _.map(classifiedFields, ({ classification, externalId }) => ({
        classification,
        externalId,
      })),
    })

    await Promise.all(
      _.map(contactsMigrationData, ({ contactField, externalField }) => {
        const { externalId } = externalField
        return this._migrateField(classifiedById[externalId].componentRef, contactField)
      }),
    )
  }
}
